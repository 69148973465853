/* eslint-disable no-var */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Alpine from "alpinejs";

import Rails from "@rails/ujs";

import { session } from "@hotwired/turbo";
import { FetchRequest } from "@rails/request.js";

// set window.locale
import "./set_locale.js";

// import ahoy from "ahoy.js"; 'ahoy' is defined but never used

// Imports functions that render predefined plots using Plotly
import "./plots.js";

// Import the Uppy for files uploads
import "./uppy";

// Import client side validation
import "./appearance.js";
import "./client_side_validation.js";
import "./tracking_code_validation.js";
import "./device_form.js";
import "./employee_form.js";
import "./service_invoice_validation.js";
import "./process_return_form_validation.js";
import "./sp_location_addresses.js";
import "./download_csv.js";
import { initDestinationTailSelect } from "./destination_select_utils";
import "./email_checker.js";
import "./check_analysis_status.js";

// Import the Google Maps API for address autocomplete
import "./address_autocomplete.js";

import "./leader_line.js";

// Import checkbox selection for device renewals
import "./device_renewals.js";

// Import purchase order form validation
import "./purchase_order_limits.js";

// Import database search for destinations
import "./destination_search.js";

// Import the device returns form
import "./device_returns.js";

// Import time_ago helpers
import "./time_ago.js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.locale = "";
if (document.querySelector("meta[name='language']")) {
  window.locale = document
    .querySelector("meta[name='language']")
    .getAttribute("content");
} else {
  window.locale = "en";
}
Rails.start();

// Set forms to opt-in for Turbo (use data-turbo="true" to enable)
session.setFormMode("optin");

require("@rails/activestorage").start();
require("channels");
require("user-agent-timezone");
require("tail.select.js/css/default/tail.select-light.css");
window.tail_select = require("tail.select.js/js/tail.select");

// This is to include tailwindcss in this pack
// see https://rubyyagi.com/tailwindcss2-rails6/
require("stylesheets/application.css");

// the match and compare methods are above alpinejs, since they are used in an alpine callback

// Case-insensitive comparison function between strings
function compare(needle, haystack) {
  if (haystack == null) {
    return;
  }
  if (typeof haystack.toLowerCase === "function") {
    return haystack.toLowerCase().includes(needle.toLowerCase());
  }
}

// Search through all the fields for a potential match with needle
// TODO: construct and use a proper search index when using this for the first time (it is a little slow on big pages)
window.match = function (needle, data) {
  if (needle == null || needle.length === 0) return true;

  // data: Object $data (https://alpinejs.dev/magics/data)
  // needle: string

  return Object.getOwnPropertyNames(data).some(function (field) {
    if (field === "search") return false;

    const value = data[field];
    if (Array.isArray(value)) {
      return value.some(function (f) {
        return compare(needle, f);
      });
    } else {
      return compare(needle, value);
      // only compare strings (having toLowerCase() method)
    }
  });
};

Alpine.directive("sort-by", (el, { expression }, { cleanup, evaluate }) => {
  const addMarker = (direction) => {
    // Add a directionality marker
    const span = document.createElement("span");
    span.classList.add("sort-direction-marker");

    // (chevron up, down, with one filled in), to indicate the table is sortable
    if (direction === "asc") {
      span.appendChild(document.createTextNode("▼"));
    } else {
      span.appendChild(document.createTextNode("▲"));
    }

    el.style.position = "relative";
    el.appendChild(span);
  };

  const clearMarkers = () => {
    // remove any directionality markers
    const v = el.parentNode.getElementsByClassName("sort-direction-marker");
    while (v.length > 0) {
      v[0].parentNode.style.position = undefined;
      v[0].parentNode.removeChild(v[0]);
    }
  };

  if (evaluate("column") === expression) {
    addMarker(evaluate("direction"));
  }

  const handler = () => {
    // update the sort column and the direction (in one go, to trigger only one request)
    evaluate(
      "column = '" +
        expression +
        "'; direction = (direction === 'asc' ? 'desc' : 'asc')",
    );

    clearMarkers();
    addMarker(evaluate("direction"));
  };

  el.addEventListener("click", handler);
  el.style.cursor = "pointer";

  cleanup(() => {
    el.removeEventListener("click", handler);
    el.style.cursor = undefined;
  });
});

window.updateIndex = async function (data) {
  const url = new URL(window.location.href);
  setUrlParams(url, data);
  await performFetchRequest(url);
  updateUrlWithoutReloading(url);
};

function setUrlParams(url, data) {
  url.searchParams.set("search", data.search);
  url.searchParams.set("archived", data.archived);
  if (data.filter === "") {
    removeFilterParam(url);
  } else {
    url.searchParams.set("filter", data.filter);
  }
  url.searchParams.set("sort", data.column);
  url.searchParams.set("direction", data.direction);
}

async function performFetchRequest(url) {
  const request = new FetchRequest("get", url.toString(), {
    responseKind: "turbo-stream",
  });
  await request.perform();
}

function updateUrlWithoutReloading(url) {
  history.pushState(null, "", url.toString());
}

function removeFilterParam(url) {
  url.searchParams.delete("filter");
  url.searchParams.delete("lop");
}

Alpine.directive("reorder-by", (el, { expression }, { cleanup, evaluate }) => {
  const handler = () => {
    reorder(el, expression);
  };

  el.addEventListener("click", handler);
  el.style.cursor = "pointer";

  cleanup(() => {
    el.removeEventListener("click", handler);
    el.style.cursor = undefined;
  });
});

Alpine.directive(
  "reorder-by-num",
  (el, { expression }, { cleanup, evaluate }) => {
    const handler = () => {
      reorder(el, expression, "num");
    };

    el.addEventListener("click", handler);
    el.style.cursor = "pointer";

    cleanup(() => {
      el.removeEventListener("click", handler);
      el.style.cursor = undefined;
    });
  },
);

Alpine.directive(
  "reorder-by-bool",
  (el, { expression }, { cleanup, evaluate }) => {
    const handler = () => {
      reorder(el, expression, "bool");
    };

    el.addEventListener("click", handler);
    el.style.cursor = "pointer";

    cleanup(() => {
      el.removeEventListener("click", handler);
      el.style.cursor = undefined;
    });
  },
);

window.Alpine = Alpine;
Alpine.start();

window.transferSearchToForm = function (model, searchString, el) {
  /* Depending on the model described in the select,
   * use a different method to transfer the search query
   * into the form:
   *
   * Name: split firstname and lastname (split on first space)
   * Address: assume everything before the first number is a street name, then street number, then zip, city
   */
  let parts;
  if (model === "user") {
    parts = searchString.match(/([^ ]*) *(.*)/).slice(1);
  } else if (model === "address") {
    parts = searchString
      .match(
        /([^0-9]*) *([0-9]*)([^, ]*)[, ]*([0-9]* *[A-Za-z]?[A-Za-z]?) *(.*)/,
      )
      .slice(1);
  } else {
    parts = [searchString];
  }

  // Now distribute the parts over the form fields
  el.closest(".select-with-add")
    .querySelectorAll(".add input")
    .forEach((input, index) => {
      if (index < parts.length) {
        input.value = parts[index];
      }
    });
};

window.clearSearchAndSelectAddNew = function (optionElement) {
  // Clear the search query
  const parent = optionElement.closest("div.select-dropdown");
  const el = parent.querySelector("input.search-input");
  el.value = "";
  el.dispatchEvent(new Event("input", { bubbles: true }));

  // select the '+ Add New' option
  // this will break if someone starts anything with a '+'
  // but there is no nice solution since tail select does not propagate the other properties
  // of the select option.
  window.setTimeout(
    () => parent.querySelector('li[data-key^="+"]').click(),
    50,
  );
};

function cbEmpty(root, searchString) {
  if (searchString === undefined) {
    return;
  }
  // Add a button to add a new entry
  root.innerHTML = `<ul class='dropdown-optgroup' data-group='#'> \
  <li class='dropdown-option new' @click='isShowing=true; window.transferSearchToForm(model, "${searchString.replace(/"/g, "'")}", $el); clearSearchAndSelectAddNew($el); select.close();'>Add "${searchString}"</li> \
  </ul>`;
}

function cbComplete(root) {
  /*
   * Add a listener to the close event, to simulate a click,
   * if there is _only one_ option remaining, and it is unselected, to get easy keyboard-only
   * navigation.
   *
   * This may be confusing if there is only one option in total, and you want it to be deselected.
   * In that case you can click the 'blob' to deselect it.
   */
  root.addEventListener("tail::close", (event) => {
    if (event.target.querySelectorAll("li.dropdown-option").length === 1) {
      const dropdownOptions = event.target.querySelectorAll(
        "li.dropdown-option:not(.selected)",
      );
      if (dropdownOptions.length === 1) {
        dropdownOptions[0].click();
      }
    }
  });
}

require("tail.select.js/css/default/tail.select-light.css");
window.tail_select = require("tail.select.js/js/tail.select");
window.tail_select.strings.register("nl", {
  all: "Alle",
  none: "Geen",
  empty: "Geen opties beschikbaar",
  emptySearch: "Geen optie gevonden",
  limit: "Het is niet mogelijk om meer opties te kiezen",
  placeholder: "Kies een optie...",
  placeholderMulti: "Kies tot :limit opties...",
  search: "Type om te zoeken",
  disabled: "Dit veld is geblokkeerd",
});

function importAll(r) {
  r.keys().forEach(r);
}
// Add relevant file extensions as needed below.
importAll(require.context("../media/images/", true, /\.(svg|jpg|png)$/));

const classNames =
  "max-w-lg block w-full shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md";
window.tail_select_options = {
  animate: false,
  search: true,
  hideDisabled: true,
  locale: window.locale,
  cbComplete,
  classNames,
};
window.tail_select_multi_options = Object.assign(
  {
    sourceBind: true,
    multiContainer: true,
  },
  window.tail_select_options,
);
window.tail_select_or_add_options = Object.assign(
  {
    cbEmpty,
  },
  window.tail_select_options,
);

const localeTexts = {
  find_device: "Find a device",
  device_not_found: "Device not found",
  device_not_in_list: "Device not in list",
};

document.addEventListener("turbo:load", () => {
  const destinationSelectElement = document.querySelector(
    'select[id*="destination_sgid"]',
  );
  initDestinationTailSelect(destinationSelectElement);

  window.tail_select(".tail_select", {
    search: true,
    locale: window.locale,
    hideDisabled: false,
    sourceBind: true,
    classNames,
  });
  // Should consider starting based on a data attribute or alpinejs x-init, and sticking the extra arguments in there
  window.tail_select(".tail_select_descriptions", {
    search: true,
    locale: window.locale,
    hideDisabled: false,
    descriptions: true,
    classNames,
  });

  let deviceSelector;
  if ((deviceSelector = document.getElementById("device_model_group_id"))) {
    localeTexts.find_device = deviceSelector.getAttribute("text_find_device");
    localeTexts.device_not_found = deviceSelector.getAttribute(
      "text_device_not_found",
    );
    localeTexts.device_not_in_list = deviceSelector.getAttribute(
      "text_device_not_in_list",
    );
  }
  const tailSelect = window.tail_select("#device_model_group_id", {
    search: true,
    placeholder: "Select your device",
    locale: window.locale,
    classNames,
    cbEmpty: (dropdownInner, searchString) => {
      const el = document.createElement("SPAN");
      el.classList.add("dropdown-empty");
      const text = document.createTextNode(localeTexts.device_not_found);
      el.appendChild(text);
      dropdownInner.appendChild(el);
    },
  });
  if (tailSelect) {
    tailSelect.on("change", () => {
      tailSelect.select.classList.remove("ring-red-300", "ring-1");
    });
    tailSelect.on("close", () => {
      if (
        tailSelect.label.innerText === localeTexts.device_not_in_list ||
        tailSelect.label.innerText === localeTexts.find_device
      ) {
        const unknownDeviceOption = document.getElementById(
          "device_model_group_id",
        ).firstChild;
        unknownDeviceOption.text = localeTexts.device_not_in_list;
        tailSelect.reload();
        tailSelect.__.empty = localeTexts.device_not_in_list;
        tailSelect.options.select(localeTexts.device_not_in_list, "#");
      }
    });

    // replace the default text for an empty search for the device selector
    tailSelect.__.empty = localeTexts.device_not_in_list;
  }
});

// Reorder tables based on x-data properties on rows
// the 'self' argument is meant to represent the thead > tr > td item which is clicked
function reorder(self, property, comparisonType = "str") {
  // Find the direction by looking for a sort marker on the current element
  const sortDirectionMarker = self.getElementsByClassName(
    "sort-direction-marker",
  )[0];
  let direction = 1;
  if (sortDirectionMarker) {
    direction = sortDirectionMarker.innerHTML === "▼" ? -1 : 1;
  }
  // Remove the sort marker from other elements.
  const v = self.parentNode.getElementsByClassName("sort-direction-marker");
  while (v.length > 0) {
    v[0].parentNode.style.position = undefined;
    v[0].parentNode.removeChild(v[0]);
  }

  // get the tbody element
  //  TODO: second part still need until we moved to grid tables on all pages
  const tbody =
    self.parentNode.nextElementSibling ||
    self.closest("table").getElementsByTagName("tbody")[0];
  const items = tbody.children;
  const elements = document.createDocumentFragment();

  const arr = [...Array(items.length).keys()];
  // sort arr into the requested order

  arr.sort((a, b) => {
    let aVal = Alpine.evaluate(items[a], "$data." + property);
    let bVal = Alpine.evaluate(items[b], "$data." + property);

    // TODO: add comparison types (boolean, integer, date?)
    // can we autodetect the comparison type based on the data type?
    if (comparisonType === "str") {
      if (aVal) {
        aVal = aVal.toLowerCase();
      } else {
        aVal = "";
      }
      if (bVal) {
        bVal = bVal.toLowerCase();
      } else {
        bVal = "";
      }
    }

    if (aVal < bVal) {
      return -direction;
    }
    if (aVal > bVal) {
      return direction;
    }

    return 0;
  });

  // Fill the shadow dom with elements
  // Note that this clones the node. We could consider not cloning, and sorting
  // inside the DOM (perhaps using our information about the sort order in-memory)
  // to efficiently update. Since the sizes are equal I don't think we should reflow
  // then and it may be fast enough.
  arr.forEach((i) => elements.appendChild(items[i].cloneNode(true)));

  // and overwrite the existing DOM (this clears any listeners I think...)
  tbody.innerHTML = null;
  tbody.appendChild(elements);

  // Add a directionality marker (and remove it elsewhere)
  const span = document.createElement("span");
  span.classList.add("sort-direction-marker");

  // instead of this direction marker it may be nicer to have a permanent one
  // (chevron up, down, with one filled in), to indicate the table is sortable
  if (direction > 0) {
    span.appendChild(document.createTextNode("▼"));
  } else {
    span.appendChild(document.createTextNode("▲"));
  }

  self.style.position = "relative";
  self.appendChild(span);
}

// TODO: Delete after moving to new CSV download method
window.download_index = function (table) {
  /*
   * Search the DOM for rows which have not been hidden (filtered)
   * and concatenate their properties into an array of arrays, which
   * we then convert to CSV. Then trigger a download of this CSV
   * by setting window.location to a data-url.
   */
  // dataset is an array of Objects.
  const dataset = Array.from(table.querySelector(".gtable-body").children)
    .filter((el) => {
      return el.checkVisibility();
    })
    .map((el) => {
      return JSON.parse(el.getAttribute("x-data"));
    });

  // Don't do anything if there are no entries left
  if (dataset.length === 0) {
    return;
  }

  // Select keys based on the first entry
  const keys = Object.keys(dataset[0]).filter((k) => {
    return !Array.isArray(dataset[0][k]);
  });

  // Build the CSV string
  const csvString =
    keys.join(";") +
    "\n" + // no need to escape the keys
    dataset
      .map((row) => {
        return keys
          .map((k) => {
            if (row[k] == null) {
              // checks for null or undefined
              return "";
            } else {
              return `"${String(row[k]).replace(/"/gi, '""')}"`;
            }
            // surround field by quotes, replace each quote " in content by ""
          })
          .join(";");
      })
      .join("\n");

  // Prepare and serve CSV URL
  const csvBlob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
  const csvUrl = URL.createObjectURL(csvBlob);

  window.location = csvUrl;
};

/* Client side form validation on form fields */
document.addEventListener("turbo:load", () => {
  // mark blank required fields as touched after a blur
  document.querySelectorAll("input, textarea").forEach((field) => {
    field.addEventListener("blur", (event) => {
      field.classList.add("touched");
    });
  });
});

/* Disable prefetching on link hovers */
document.addEventListener("turbo:load", () => {
  document.querySelectorAll("a").forEach((link) => {
    link.setAttribute("data-turbo-prefetch", "false");
    link.setAttribute("data-turbo-method", "get");
  });
});
