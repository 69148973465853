import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
  Alpine.data("employeeForm", () => ({
    selectedJobTitleId:
      document.getElementById("employee_form").dataset.selectedJobTitleId ||
      null,
    loadoutProfileOverrideSgid:
      document.getElementById("employee_form").dataset
        .loadoutProfileOverrideSgid || null,
    jobTitleLoadoutProfileName: null,
    loadoutProfiles:
      JSON.parse(
        document.getElementById("employee_form").dataset.loadoutProfiles,
      ) || [],
    loadoutProfileSelect: document.querySelector(
      'select[name="employee[loadout_profile_sgid]"]',
    ),

    init() {
      this.updateLoadoutProfiles();
    },

    createOption(value, text) {
      const option = document.createElement("option");
      option.value = value;
      option.textContent = text;
      return option;
    },

    updateLoadoutProfiles() {
      const defaultProfile = this.findDefaultProfile();

      if (defaultProfile) {
        this.jobTitleLoadoutProfileName = defaultProfile.name;
        this.markDefaultOption(defaultProfile.sgid);
      } else {
        this.markDefaultOption("");
      }
    },

    findDefaultProfile() {
      return this.loadoutProfiles.find((profile) =>
        profile.job_titles.includes(parseInt(this.selectedJobTitleId)),
      );
    },

    markDefaultOption(sgid) {
      this.clearOptions();

      const defaultOptionText = this.getDefaultOptionText(sgid);
      const defaultOption = this.createOption("", defaultOptionText);
      this.loadoutProfileSelect.appendChild(defaultOption);

      this.addLoadoutProfiles();

      this.setSelectedOption(defaultOption);
    },

    clearOptions() {
      this.loadoutProfileSelect.innerHTML = "";
    },

    getDefaultOptionText(sgid) {
      return sgid
        ? `Through job title (${this.jobTitleLoadoutProfileName})`
        : "Through job title (unassigned)";
    },

    addLoadoutProfiles() {
      this.loadoutProfiles.forEach((profile) => {
        const option = this.createOption(profile.sgid, profile.name);
        this.loadoutProfileSelect.appendChild(option);
      });
    },

    setSelectedOption(defaultOption) {
      if (this.loadoutProfileOverrideSgid) {
        this.loadoutProfileSelect.value = this.loadoutProfileOverrideSgid;
      } else {
        this.loadoutProfileSelect.value = "";
      }
    },
  }));
});
